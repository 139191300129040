import Head from 'next/head'
import Link from 'next/link'

import generateRssFeed from '~/services/rss'

export default function Index() {
  return (
    <>
      <Head>
        <title>ashe.gay</title>
        <meta name="description" content="a semi-public collection of notes, letters, and essays from your local trans web witch" />
      </Head>
      <div className="indexContainer">
        <h1>ashe.gay</h1>
        <p>a semi-public collection of notes, letters, and essays from your local trans web witch</p>
        <p className="link"><Link href="/posts"><a>posts</a></Link></p>
      </div>
    </>
  )
}

export async function getStaticProps() {
  await generateRssFeed()

  return {props: {}}
}
